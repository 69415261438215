<!--/**-->
<!--* 自动生成 vue导入组件代码【模块名：库位】-->
<!--* 生成日期：2024/04/25 11:20:49-->
<!--* 生成路径: src/pages/warehouse_location/ImportWarehouseLocation.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle' center
               width='60%'>
      <el-row :gutter='15'>
        <el-col :span='7'>
          <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
            <el-form-item label='新增数量' prop='num'>
              <el-input-number v-model='form.num' :min='0' :max="10" label='新增数量' placeholder='新增数量' @change="handleChangePreview" />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span='7' v-if='importList.length > 0'>
          <div class='default-table'>
            <el-divider>预览</el-divider>
            <ImportTable :showIndex='true'
                         :tableData='importList'
                         :columns='columns'
                         border
                         :row-class-name='rowClassName'
            ></ImportTable>
          </div>
          <div style='text-align: center;padding: 10px'>
            <el-button type='primary' :disabled='loadingCommit' :loading='loadingCommit' @click='onsubmit()'>提交
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import ImportTable from '@/components/common/ImportTable'

export default {
  name: 'AddCustomBarcode',
  components: { ImportTable, ExportBtn },
  data() {
    return {
      dialogTitle: '新增条码',
      columns: [{ field: 'barcode', title: '条码', width: "120", horizontal: 'center' }],
      importList: [],
      loadingCommit: false,
      len: 0,
      importedIndexList: [],
      // 表单校验
      rules:{num: [{ required: true, message: '新增数量不能为空', trigger: 'blur' }]},
      // 表单参数
      form: {},
    }
  },
  methods: {
    onOpen() {
      this.importList = []
      this.len = 0
      this.importedIndexList = []
      this.form = {num:0}
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    onsubmit() {
      this.len = 0
      this.importedIndexList = []
      this.loadingCommit = true
      if (this.importList.length > 0) {
        this.saveRow(0)
      }
    },
    async saveRow(index) {
      // 有效表格数据索引
      if (index < this.importList.length) {
        if (this.importedIndexList.indexOf(index) === -1) {
          this.importedIndexList.push(index)
          const row = this.importList[index]
          await this.$api.saveCustomBarcode(row)

          setTimeout(() => {
            index++
            this.saveRow(index)
          }, 100)
        }
      }else {
        setTimeout(() => {
          this.loadingCommit = false
        }, 500)
        this.$emit('imported')
      }
    },
    // eslint-disable-next-line no-unused-vars
    rowClassName({ row, rowIndex }) {
      if (this.importedIndexList.indexOf(rowIndex) === -1) {
        return 'wait-upload'
      } else {
        return 'uploaded'
      }
    },
    // 数据预览
    handleChangePreview() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$api.previewBarcodeBatchAdd(this.form).then(data => {
            this.importList = [];
            if (data.length !== undefined && data.length > 0) {
              this.importList = data;
            }
          })
        }
      })
    },
  }
}
</script>

<style>
.wait-upload {
  color: #409EFF;
}

.uploaded {
  color: #67C23A;
}

</style>
