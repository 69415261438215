<!--/**-->
<!--* 自动生成 vue列表代码【模块名：自定义条码】-->
<!--* 生成日期：2024/05/07 17:23:45-->
<!--* 生成路径: src/pages/custom_barcode/CustomBarcodeList.vue-->
<!--* 生成人：管理员-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <custom-barcode-print ref='print' />
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='条码编号'>
              <el-input v-model='searchCondition.barcode' placeholder='条码编号' clearable></el-input>
            </el-form-item>
            <el-form-item label='产品名称'>
              <el-input v-model='searchCondition.title' placeholder='产品名称' clearable></el-input>
            </el-form-item>
            <el-form-item label='状态'>
              <el-select v-model='searchCondition.state' placeholder='绑定状态' clearable>
                <el-option v-for='item in bindOptions' :key='item.value'
                           :label='item.label' :value='item.value'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' @click='openAdd=true'>新增</el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-printer' @click='handlePrint'>批量打印</el-button>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable ref='table'
                       :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column slot='first-column' type='selection' width='50' align='center' />
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-printer' @click='handlePrint(row)'>打印</el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>
      <!-- 新增 -->
      <AddCustomBarcode :visible.sync='openAdd' @imported='handleQuery' />
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import CustomBarcodePrint from '@/pages/custom_barcode/Print.vue'
import AddCustomBarcode from '@/pages/custom_barcode/AddCustomBarcode.vue'

export default {
  name: 'CustomBarcodeList',
  components: { AddCustomBarcode, CustomBarcodePrint, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'custom_barcode',
      loading: false,
      loadingCommit: false,
      dataList: [],
      barcodeList: [],
      total: 0,
      height: 800,
      searchCondition: { barcode: null, title: null, state: null },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'desc' },
      bindOptions: {
        0: { label: '未绑定', value: 2, color_value: '#f56c6c' },
        1: { label: '已绑定', value: 1, color_value: '#67c23a' }
      },
      defaultColumns: [{ hide: false, prop: 'barcode', label: '条码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: '_state', label: '状态', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'product_id', label: '产品id', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'title', label: '产品名称', width: 120, sortable: false, fixed: false }],
      // 弹出层标题
      title: '新增/修改-条码',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: { num: [{ required: true, message: '新增数量不能为空', trigger: 'blur' }] },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openAdd: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:custom_barcode'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getCustomBarcodeList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteCustomBarcode(id)
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    handlePrint: function(row) {
      // 打印
      let selection
      if (row.id === undefined) {
        // 批量
        selection = this.$refs.table.interfaceGetSelection()
      } else {
        // 单个指定
        selection = [row]
      }
      if (selection.length < 1) {
        this.$message.warning('请选择要打印的条码')
        return
      }
      const locations = selection.map(_ => {
        return `${_.barcode}`
      })
      // 打印
      this.$refs.print.print(locations)
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
