<template>
  <el-drawer title='批量打印' :visible.sync='visible' custom-class='warehouse-print-container'>
    <div ref='content' :key='key'>
<!--      overflow-y: hidden;-->
      <div v-for='(datum,index) in collection' :key='index'
           :style='`position: relative;height:140px;padding-top:${index>0?6:0}px;width: 220px;margin: 0 auto;`'>
        <div :key='`${datum.id}`' :id='`${datum.id}`'
             :style='`height:${height}px; width: ${width}px;position:absolute;right:47px;`' />
        <div style='display:inline-block;line-height: 250px;width: 100%;text-align: center;position: relative;right: -11px'>
          <div style='font-size: 14px;font-weight: 700;'>{{ datum.value }}</div>
        </div>
      </div>
    </div>
    <div style='height: 80px' />
    <div class='footer'>
      <ml-button size='medium' @click.native='handleSure' :text='`立即打印${collection.length}张`' />
    </div>
  </el-drawer>
</template>
<script>

import MlButton from '@/components/MlButton/index.vue'
import QRCode from 'qrcodejs2'
// 得力DL-720W | 203DPI分辨率?

const getKey = function() {
  return new Date().getTime() + ''
}
export default {
  name: 'WarehouseLocationPrint',
  components: { MlButton },
  data() {
    return {
      key: getKey(),
      collection: [],
      visible: false,
      width: 114,
      height: 114
    }
  },
  // created() {
  //   this.print(['A1-1', 'A3-9-5'])
  // },
  methods: {
    print: function(data) {
      this.init(data)
      this.key = getKey()
      this.visible = true
    },
    init: function(data) {
      let collection = []
      if (Array.isArray(data)) {
        data = Array.from(new Set(data))// 数组去重
        data.map(datum => {
          collection.push({ id: `print:${datum}`, value: datum })
        })
      } else {
        collection = [{ id: `print:${data}`, value: data }]
      }
      this.collection = collection
      // 渲染
      this.render()
    },
    generateCode: function(id, text) {
      text = text + ''
      const dom = document.getElementById(id)
      if (dom) {
        new QRCode(id, { width: this.width, height: this.height, text })
      }
    },
    render: function() {
      const _this = this
      _this.$nextTick(() => {
        _this.collection.map(_ => {
          _this.generateCode(_.id, _.value)
        })
      })
    },
    handleSure: function() {
      if (document.getElementsByClassName('border-print-test')){
        // style.border = '1px solid #000'
        document.getElementsByClassName('border-print').item((row)=>{
          row.style.border = 'none';
        })
      }
      if (document.getElementById('print-iframe')) {
        document.body.removeChild(document.getElementById('print-iframe'))
      }
      let iframe = document.getElementById('print-iframe')
      if (!iframe) {
        const el = this.$refs.content
        iframe = document.createElement('IFRAME')
        let doc = null
        iframe.setAttribute('id', 'print-iframe')
        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;')
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        doc.write(`
              <style type='text/css'>
                /* 浏览器打印基本样式 */
                @media print {


                  @page {
                    size: auto;
                    margin: 0;
                     /* 影响打印小票的边缘margin */
                  }
                }
              </style>
              <div style='width: 220px;margin:0 auto;'>
                ${el.innerHTML}
              </div>`)
        doc.close()
        iframe.contentWindow.focus()
      }

      iframe.contentWindow.print()
      this.$notify.info('开始打印')
    }
  }
}
</script>
<style lang='scss'>
.warehouse-print-container {
  width: 500px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 40px 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  //测试样式
  .border-print-test{
    border: #0C0C0C 1px dashed;
  }
}
</style>